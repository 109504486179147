import { RotateCcw as UndoIcon } from "lucide-react";
import { Fragment, useEffect, useRef } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import Message from "./message";

export default function Messages({ events, isProcessing }) {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (events.length > 2) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [events.length]);

  return (
    <section className="w-full min-h-0 grow overflow-auto px-6">
      {events.map((ev, index) => {
        // if (ev.image) {
        //   return (
        //     <Fragment key={"image-" + index}>
        //       {/* <Message sender="replicate" shouldFillWidth>
        //         <Image
        //           alt={
        //             ev.prompt
        //               ? `The result of the prompt "${ev.prompt}" on the previous image`
        //               : "The source image"
        //           }
        //           width="512"
        //           height="512"
        //           priority={true}
        //           className="w-full h-auto rounded-lg"
        //           src={ev.image}
        //         />
        //       </Message> */}

        //       {(isProcessing || index < events.length - 1) && (
        //         <Message sender="oracle" isSameSender>
        //           What should we change now?
        //         </Message>
        //       )}
        //     </Fragment>
        //   );
        // }

        if (ev.role === "assistant") {
          return (
            <Message key={"oracle-" + index} sender="oracle">
              {ev.content}
            </Message>
          );
        }

        if (ev.role === "user") {
          return (
            <Message key={"user-" + index} sender="user">
              {ev.content}
            </Message>
          );
        }

        return (
          <div>Error</div>
        );
      })}

      {isProcessing && (
        <Message sender="oracle">
          <PulseLoader color="#999" size={7} />
        </Message>
      )}

      <div ref={messagesEndRef} />
    </section>
  );
}
