// src/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Info as InfoIcon } from "lucide-react";

const HomePage = () => {
  return (
    <div className="flex flex-col items-center bg-white overflow-x-hidden">
      <Link to="/about" className='absolute top-10 right-10'>
        <div className='flex content-center items-center'>
          <InfoIcon className="icon flex" />
          <h1 className="text-xs">About Us</h1>
        </div>
      </Link>
      <div className='w-3/4 min-h-fit mt-20 sm:mt-16 max-w-6xl'>
        <div className="flex flex-col items-center">
          <div className='gradient-title w-1/2 sm:w-1/4 aspect-square'></div>
          <div 
            className="hidden sm:block h0 whitespace-nowrap text-center uppercase"
            style={{ marginTop: '-10%', lineHeight: '1.2'}}
          >
            venture oracle
          </div>
          <div 
            className="block sm:hidden text-[2.2rem] whitespace-nowrap text-center uppercase"
            style={{ marginTop: '-14%'}}
          >
            venture oracle
          </div>
          <a href="https://menlovc.com/" target="_blank" rel="noreferrer" className="block sm:hidden text-[1.4rem] whitespace-nowrap text-center  is-accent uppercase hover:brightness-125">
            Menlo Ventures
          </a>
          <div className='flex w-full grow px-2'>
            <div className="subtitle whitespace-nowrap text-left grow hidden md:block">
              AI tools for venture capital powered by the
              {' '}<Link to="/op" className='underline'>OP</Link>{' '}
              stack
            </div>
            <a href="https://menlovc.com/" target="_blank" rel="noreferrer" className="subtitle whitespace-nowrap text-center hidden sm:block is-accent uppercase hover:brightness-125">
              Menlo Ventures
            </a>
          </div>
        </div>
      </div>
      <div className='px-10 md:px-24 sm:mt-24 flex w-full flex-wrap justify-center mb-12'>
          {/* <Link 
            to="/chat"
            className="m-4 pt-2 rounded-xl border-hairline w-96 flex grow flex-col items-center aspect-square relative overflow-hidden"
          >
            <div className="h-1/2 flex grow flex-col items-center pt-6">
              <div className="w-32 aspect-square gradient-secondary absolute top-8"></div>
              <div className='w-16 aspect-square bg-white rounded-lg mt-8 z-10 flex items-center justify-center'>
                <img className="p-3" src="chatgvc.svg" alt="Chatgvc logo" />
              </div>
              <h1 className="text-3xl mb-4 mt-6">ChatGVC</h1>
              <h2 className="text-xs text-center text-gray-400 px-12 mb-8 w-3/4">A chatbot agent with venture capital knowledge, internet access, and graphical tools.</h2>
            </div>
            <img className="w-3/4 pt-12" src="chatgvc_window.svg" alt="Chatgvc logo" />
          </Link> */}
          <Link 
            to="/map"
            className="m-4 pt-2 rounded-xl border-0 sm:border-2 border-black border-opacity-10 hover:border-opacity-30 w-96 flex grow flex-col items-center sm:aspect-square relative overflow-hidden max-w-2xl"
          >
            <div className="h-1/2 flex grow flex-col items-center pt-6 sm:pt-12">
              <div className="w-32 aspect-square gradient-primary absolute top-14"></div>
              <div className='relative w-32 sm:w-16 aspect-square bg-white drop-shadow-md rounded-[30px] sm:rounded-lg mt-8 z-10 flex items-center justify-center'>
                <img className="absolute w-3/5 sm:w-14 pt-2" src="cartographer.svg" alt="Cartographer logo" />
                <div className='block sm:hidden bg-blue-500 rounded-full py-1 px-2 absolute top-[-10px] right-[-15px] flex items-center justify-center text-white'>
                    <p>New</p>
                </div>
              </div>
              <h1 className="text-3xl sm:text-3xl mb-4 mt-6">Cartographer</h1>
              <h2 className="sm:hidden text-xs text-center text-gray-400 px-12 mb-8 sm:w-3/4">Generative AI Meets Market Maps</h2>
              <h2 className="hidden sm:block text-xs text-center text-gray-400 px-12 mb-8 sm:w-3/4">On-demand market map generation across the current startup landscape.</h2>
            </div>
            <img className="hidden sm:block w-2/3 pt-12" src="cartographer_window.svg" alt="Cartographer logo" />
          </Link>
        </div>
    </div>
  );
};

export default HomePage;
