// src/App.js
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { 
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  SlidersHorizontal as SlidersHorizontalIcon,
  Search as SearchIcon,
  Plus as PlusIcon,
  X as XIcon,
  History as HistoryIcon,
  Undo2 as UndoIcon,
  SidebarClose as SidebarCloseIcon,
} from "lucide-react";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useLocalStorageList, getImgCategoryFromURL } from '../lib/utils';

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

function App() {

  //set default values 

  const [subcategories, setSubcategories] = useState([]);

  const DEFAULT_NUM = 5;

  const defaultCategories = [
    { name: 'Writing Assistants', checked: true, value: DEFAULT_NUM, editable: true },
    { name: 'Video Editing', checked: true, value: DEFAULT_NUM, editable: true },
    { name: 'Image Generation', checked: true, value: DEFAULT_NUM, editable: true },
    { name: 'Code Generation', checked: true, value: DEFAULT_NUM, editable: true },
    { name: 'Sales', checked: true, value: DEFAULT_NUM, editable: true },
    { name: 'Legal Assistants', checked: true, value: DEFAULT_NUM, editable: true },
    { name: 'Infrastructure', checked: true, value: DEFAULT_NUM, editable: true },
  ];

  // const [showSubcategories, setShowSubcategories] = useState(false);

  const [query, setQuery] = useState('');
  const [prevQuery, setPrevQuery] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [currImageSrc, setCurrImageSrc] = useState('');
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [history, setHistory] = useLocalStorageList('history', []);

  const API_URL = process.env.REACT_APP_API_URL;

  const addHistory = (filename) => {
    setHistory([...history, filename]);
  }

  const toggleManualEntry = () => {
    if (!isManualEntry) {
      setSubcategories(defaultCategories)
    } else {
      setSubcategories([]);
    }
    setIsManualEntry(!isManualEntry);
  }

  const openModal = (filename) => {
    setCurrImageSrc(filename);
    setModalOpen(true);
  }

  const closeModal = (event) => {
    if (event.target.id === 'modal-bg' || event.target.id === 'modal-close') {
      setModalOpen(false);
    }
  }

  const createSubcategoryObject = (item, index) => {
    return {
      id: index, 
      name: item.subcategory,
      description: item.description,
      checked: true,
      value: DEFAULT_NUM,
      editable: true
    }
  }

  const generateSubcategories = async () => {
    //send query to server

    //set loading state
    setIsSearchLoading(true);

    const data = JSON.stringify({
      query: query
    });

    console.log(data);

    try {
      const response = await axios.post('/api/generate_subcategories/', data,
        {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
          }
        }
      );

      //create subcategories from response
      console.log(response.data.subcategories);
      const subcategories = response.data.subcategories.map(createSubcategoryObject);
      console.log(subcategories);
      
      setIsManualEntry(false);
      setSubcategories(subcategories);
      setPrevQuery(query);
      
    } catch (error) {
      console.error(error);
    }
    //set loading state
    setIsSearchLoading(false);
  };

  const addSubcategoryInput = () => {
    setSubcategories([...subcategories, { name: '', checked: false, value: DEFAULT_NUM, editable: true }]);
  };

  const removeSubcategoryInput = (index) => {
    const newCategories = [...subcategories];
    newCategories.splice(index, 1);
    setSubcategories(newCategories);
  };

  const updateSubcategory = (index, updatedSubcategory) => {
    const newCategories = subcategories.map((Subcategory, i) => {
      if (i === index) {
        return updatedSubcategory;
      }
      return Subcategory;
    });
    setSubcategories(newCategories);
  };

  const showLink = (event) => {
    event.preventDefault();
    console.log('Submit clicked');
  };

  const getImage = async () => {
    //set loader to true
    setIsSubmitLoading(true);

    //get the name and number for each Subcategory
    const SubcategoryValues = subcategories.map(Subcategory => Subcategory.value); 
    const SubcategoryNames = subcategories.map(Subcategory => Subcategory.name);

    //convert the query to have the first letter of each word capitalized 
    const capitalizedQueryWords = prevQuery.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    const data = JSON.stringify({
      category: capitalizedQueryWords,
      names: SubcategoryNames,
      numbers: SubcategoryValues
    })

    try {
      const submit_response = await axios.post('/api/calculate_categories', data, 
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      });

      const image_url = submit_response.data.public_url;

      console.log(submit_response);

      if (image_url === null) {
        setIsSubmitLoading(false);
        console.log('No image generated');
        return;
      }

      setModalOpen(true);
      setCurrImageSrc(image_url);
      addHistory(image_url);

      setIsSubmitLoading(false);

    } catch (error) {

      //hide loader & report error
      setIsSubmitLoading(false);
      console.log(error);
    }
  };

  return (
  <div className={classNames({
      // grid layout  
      "grid min-h-screen": true,
      // toggle width
      "grid-cols-sidebar": isSidebarOpen,
      "grid-cols-sidebar-collapsed": !isSidebarOpen,
      // transition classes
      "transition-[grid-template-columns] duration-300 ease-in-out": true,
    })
  }
  >
    <Helmet>
      <title>Venture Oracle | Cartographer</title>
    </Helmet>

    <Link to="/" className='flex items-center absolute top-5 left-5 sm:left-10 sm:top-10 z-20'>
      <UndoIcon />
    </Link>
    <div className="flex items-start justify-center h-screen overflow-y-scroll grow">
      <div className="max-w-screen-md p-5 flex flex-col items-center mb-10 sm:mt-2">
        {isModalOpen && currImageSrc !== '' && (
          <div id="modal-bg" className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20" onClick={closeModal}>
            <div className="bg-white rounded-xl shadow-lg relative overflow-hidden w-5/6 min-w-[96px] max-w-[120em]" >
              <img src={currImageSrc} alt={getImgCategoryFromURL(currImageSrc)} className='object-cover w-full pt-12 px-4'></img>
              <div className='p-4'>
                {/* <h2 className="text-3xl">{prevQuery} Market Map</h2> */}
                <button className='absolute right-5 top-5'>
                  <XIcon className="w-5 text-gray-600" 
                    id="modal-close"
                    onClick={closeModal}
                  /> 
                </button>
              </div>
            </div>
          </div>
        )}

        <hgroup className='mt-8 sm:mt-16'>
          <div className='mb-4 sm:mb-12 flex justify-center items-center'>
            <img className="w-16 sm:w-32" src="cartographer.svg" alt="Chatgvc logo" />
            <h1 className="text-center text-3xl sm:text-7xl">Cartographer</h1>
          </div>
          {/* <p className="text-center text-sm text-gray-400 mb-8">
            Generate a comprehensive market map for any industry. Level up your market research.
          </p> */}
        </hgroup>
        <div className="text-[8px] ml-6 sm:ml-1 sm:text-sm text-gray-500 w-full whitespace-nowrap text-left grow">
          Start with a keyword or industry.
        </div>
        <div className='w-[20rem] sm:w-[30rem] md:w-[45rem] flex mt-2 justify-center'>
          <div className='border sm:border-2 border-black rounded-full py-1 sm:py-2 pl-6 pr-3 flex w-full'>
            <input 
              className="border-none focus:ring-transparent outline:none focus:outline-0 placeholder:italic placeholder:text-slate-400 bg-white w-full text-[10px] sm:text-sm" 
              placeholder="Generative Content Creation..." 
              type="text" 
              name="search"
              value={query} 
              onChange={e => setQuery(e.target.value)}
              onKeyPress={e => e.key === 'Enter' ? generateSubcategories() : null}
            />
          </div>
          <button 
            className='border-black border sm:border-2 aspect-square w-12 sm:w-16 ml-4 rounded-full flex items-center justify-center'
            onClick={generateSubcategories}
          >
            { !isSearchLoading 
                ? <SearchIcon className='w-4 sm:w-full' />
                : <div className='loader'></div>
            }
          </button>
        </div>
        <div className='mt-6' onClick={toggleManualEntry}>
          <div className="text-md text-gray-500 whitespace-nowrap text-left grow hidden md:block">
              Or, 
              {' '}<button className='text-gray-800 hover:text-gray-700'>choose your subcategories</button>{' '}
              manually
          </div>
        </div>
        { (isManualEntry || subcategories.length > 0) && 
          <div className='w-full sm:mt-10'>
            { isManualEntry 
              ? <div className='sm:text-3xl py-4'>Enter Your Subcategories</div> 
              : <div className='sm:text-3xl py-4'>Generated Categories for {prevQuery}</div> 
            }
            <div className='border sm:border-2 border-black rounded-xl sm:rounded-3xl py-6 sm:py-10 px-4 sm:px-16'>
              <div className='w-full text-right text-[10px] text-gray-400 mb-2 pr-2'>Max Companies</div>
              <form onSubmit={showLink}>
                  {subcategories.map((subcategory, index) => (
                    <SubcategoryInput
                        key={index}
                        subcategory={subcategory}
                        onChange={(updatedSubcategory) => updateSubcategory(index, updatedSubcategory)}
                        onDeleteSubcategory={() => removeSubcategoryInput(index)}
                    />
                  ))}
                  <div className='flex items-center justify-center'>
                    <PlusIcon className="icon text-gray-400" />
                    <button type="button" className="text-center text-xs sm:text-md text-gray-400" onClick={addSubcategoryInput}>
                        Add another Subcategory
                    </button>
                  </div>
              </form>
            </div> 
            <div className='flex justify-end'>
              <button 
                className="border sm:border-2 border-black rounded-full mt-4 py-2 pl-6 pr-3 flex items-center right-0"
                onClick={getImage}
              >
                <p className='text-md sm:text-lg mr-2 '>Generate Map</p>
                { !isSubmitLoading 
                    ? <ArrowRightIcon />
                    : <div className='ml-1 loader'></div>
                }
              </button>
            </div>
          </div>
        }
      </div>
    </div>
    <div id="sidebar" className='bg-transparent hidden md:flex relative'>
      <button 
        onClick={()=> setSidebarOpen((prev) => !prev)} 
        className='items-center w-10 h-10 mt-8 left-[-55px] bg-transparent absolute'
      >
        { !isSidebarOpen
          ? <HistoryIcon />
          : <SidebarCloseIcon  className='rotate-180'/>
        }
      </button>
      <div className='relative w-full items-start justify-center overflow-scroll border-gray-300 border sm:border-2 bg-gray-100 h-screen'>
        <div className='flex flex-col items-center w-full'>
          <div className='text-lg mt-10 text-black mb-4'>History</div>
          <div className='flex flex-col w-full'>
            { history.map((item, index) => (
              <div className='flex flex-col w-full'>
                <img 
                  src={item} 
                  onClick={()=> openModal(item) }
                  alt={getImgCategoryFromURL(item)+' market map'}
                  className='object-cover w-full px-6 pt-4 hover:scale-[105%] transition duration-300'>
                </img>
              </div>
            ))}
          </div>
        </div>  
      </div>               
    </div>
  </div>
  );
}

const SubcategoryInput = ({ key, subcategory, onChange, onDeleteSubcategory }) => {
  const handleInputChange = (event, key) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    onChange({ ...subcategory, [key]: value });
  };

  return (
    <div className="category-input flex items-center relative w-full">
      <label className='grow'>
        {/* <input
          type="checkbox"
          className="category-checkbox"
          // checked={subcategory.checked}
          onChange={(event) => handleInputChange(event, 'checked')}
        />{' '} */}
        { subcategory.editable 
          ? <input
              type="text"
              className='w-full py-1 px-2 text-xs sm:text-sm'
              placeholder="Enter Subcategory"
              value={subcategory.name}
              onChange={(event) => handleInputChange(event, 'name')}
            /> 
          : <input
              type="text"
              className='w-full py-1 px-2'
              value={subcategory.name}
              readOnly
              disabled
            /> 
        }
      </label>
      <input
        type="number"
        className="category-number mr-2 text-xs sm:text-sm"
        placeholder="Enter number"
        max="16"
        min="1"
        value={subcategory.value}
        onChange={(event) => handleInputChange(event, 'value')}
      />
      { subcategory.editable && 
        <button>
          <XIcon className="w-4 sm:w-5 text-gray-400" 
            //remove Subcategory input
            onClick={() => onDeleteSubcategory()}
          /> 
        </button>
      }
    </div>
  );
};

export default App;
