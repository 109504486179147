import { useState, useEffect } from 'react';

export const useLocalStorageList = (key, initialValue) => {
    // Get the initial value from localStorage or use the provided initialValue
    const [list, setList] = useState(() => {
      const storedList = localStorage.getItem(key);
      return storedList ? JSON.parse(storedList) : initialValue;
    });
  
    // Update localStorage whenever the list changes
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(list));
    }, [key, list]);
  
    return [list, setList];
  };

export const getImgCategoryFromURL = (url) => {
  const parts = url.split('/');
  
  // Get the last item in the array
  const lastItem = parts[parts.length - 1];
  
  // Get the category from the last item
  const category = lastItem.split('_')[0];

  return category;
}