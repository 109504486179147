// src/AboutPage.js
import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowLeft as ArrowLeftIcon } from "lucide-react";

export default function OP() {
  return (
    <div>

      <main className="flex items-center min-h-screen">
        <div className="container max-w-[600px] mx-auto p-5">
            <h1 className="text-center text-6xl mb-8 uppercase">
                The OP Stack
            </h1>

            <div className="prose">
                The OP (OpenAI + Pinecone) stack is a developer toolset for AI applications that is quickly becoming the standard for semantic search, document Q&A, content generation, chatbots, etc.
                It is a combination of the following tools:
                <li className='mt-2'>
                <a href="https://platform.openai.com/docs/models" target="_blank" rel="noreferrer" className="whitespace-nowrap underline hover:brightness-125">OpenAI's Models</a> - OpenAI's GPT models are a category of powerful language models capable of generating text with a number of impressive <a href="https://openreview.net/forum?id=yzkSU5zdwD" target="_blank" rel="noreferrer" className="whitespace-nowrap underline hover:brightness-125">emergent properties</a>. Their embedding models are also commonly used for search, clustering, recommendations, and classication tasks. 
                </li>
                <li className='mt-2'>
                <a href="https://www.pinecone.io/" target="_blank" rel="noreferrer" className="whitespace-nowrap underline hover:brightness-125">Pinecone</a> - Pinecone is a scalable, high-performance, and low-latency vector search engine. It is capable of indexing and searching through millions of vectors in milliseconds. It is the preferred vector search engine for production use-cases
                </li>
            </div>
            <div className="video-responsive my-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/-dZrNj2mVHo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="flex w-full justify-center">
                <Link to="/" className='button-primary'>
                    <ArrowLeftIcon className="icon" />
                    <p>Back</p>
                </Link>
            </div>
        </div>
      </main>
    </div>
  );
}