// src/AboutPage.js
import React from 'react';
import Messages from "../components/messages";
import PromptForm from "../components/prompt-form";
import { useEffect, useState } from "react";
import { ArrowLeft as ArrowLeftIcon } from "lucide-react";
import axios from 'axios';
import { Helmet } from 'react-helmet';

import Footer from "../components/footer";

import { Link } from 'react-router-dom';

// import prepareImageFileForUpload from "lib/prepare-image-file-for-upload";
// import { getRandomSeed } from "lib/seeds";

// const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const appName = "ChatGVC";
export const appSubtitle = "Your AI Copilot for Company Research, Due Dilligence, and Analysis.";
export const appMetaDescription = "Your AI Copilot for Company Research, Due Dilligence, and Analysis.";

export default function Home() {
  const [events, setEvents] = useState([]);
  // const [predictions, setPredictions] = useState([]);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // const [seed] = useState(getRandomSeed());
  const [initialPrompt, setInitialPrompt] = useState(""); //seed.prompt

  // set the initial image from a random seed
  // useEffect(() => {
  //   setEvents([{ image: seed.image }]);
  // }, [seed.image]);

  const handleSubmit = async (e) => {

    e.preventDefault();

    const prompt = e.target.prompt.value;

    if (!prompt) {
      return;
    }

    setError(null);
    setIsProcessing(true);
    setInitialPrompt("");

    //TOOLS: 
    // - google search 
    // - vector db
    // - create market map 

    // make a copy so that the second call to setEvents here doesn't blow away the first.
    const myEvents = [...events, { role: "user", content: prompt }];
    setEvents(myEvents);

    const data = JSON.stringify({
      prompt: prompt
    });

    try {
      const response = await axios.post('/api/submit_message/', data,
      {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      }
      );
      setEvents(
        myEvents.concat([
          { role: "assistant", content: response.data.message },
        ])
      );
    } catch (error) {
      console.error(error);
      setEvents(
        myEvents.concat([
          { role: "assistant", content: 'Error loading content. Please try again' },
        ])
      );
    }

    // while (
    //   prediction.status !== "succeeded" &&
    //   prediction.status !== "failed"
    // ) {
    //   await sleep(500);
    //   const response = await fetch("/api/" + prediction.id);
    //   prediction = await response.json();
    //   if (response.status !== 200) {
    //     setError(prediction.detail);
    //     return;
    //   }

    //   // just for bookkeeping
    //   setPredictions(predictions.concat([prediction]));

    //   if (prediction.status === "succeeded") {
    //     setEvents(
    //       myEvents.concat([
    //         { image: prediction.output?.[prediction.output.length - 1] },
    //       ])
    //     );
    //   }
    // }

    setIsProcessing(false);
  };

  const startOver = async (e) => {
    e.preventDefault();
    setEvents([]);
    setError(null);
    setIsProcessing(false);
  };

  return (
    <div>
      <Helmet>
        <title>Venture Oracle | ChatGVC</title>
      </Helmet>

      <main className="flex items-center justify-center min-h-screen">
        <div className="container max-w-screen-md h-screen p-5 flex flex-col">

          <Link to="/" className='flex items-center absolute left-10 top-10'>
            <ArrowLeftIcon className="icon" />
            <p>Back</p>
          </Link>

          <hgroup className='mt-16'>
            <div className='mb-6 flex justify-center'>
              <img src="chatgvc.svg" alt="Chatgvc logo" />
              <h1 className="text-center text-7xl ml-6">{appName}</h1>
            </div>
            <p className="text-center text-md text-gray-400 m-6">
              {appSubtitle}
            </p>
          </hgroup>

          <Messages
            events={events}
            isProcessing={isProcessing}
            onUndo={(index) => {
              setInitialPrompt(events[index - 1].prompt);
              setEvents(
                events.slice(0, index - 1).concat(events.slice(index + 1))
              );
            }}
          />

          <div className="align-self-end">
            <PromptForm
              initialPrompt={initialPrompt}
              isFirstPrompt={events.length === 1}
              onSubmit={handleSubmit}
              disabled={isProcessing}
            />

            <div className="mx-auto w-full">
              {error && <p className="bold text-red-500 pb-5">{error}</p>}
            </div>

            <Footer
              events={events}
              startOver={startOver}
            />
          </div>
        </div>
      </main>
    </div>
  );
}