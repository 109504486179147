// src/AboutPage.js
import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowLeft as ArrowLeftIcon } from "lucide-react";

export default function About() {
  return (
    <div>

      <main className="flex items-center min-h-screen">
        <div className="container max-w-[600px] mx-auto p-5">
          <h1 className="text-center text-6xl mb-14 uppercase">
            Venture Oracle
          </h1>

          <div className="prose">
            This website currently provides a single tool with more planned: 
            {/* <li className='mt-6'>
              ChatGVC ( Chat Generative Venture Capitalist ) - A simple interface for using an chat-based agent to automate company research and produce actionable insights. For example, You can ask the agent to tell you about all the key startups in an industry, describe the outcomes of previous exits, or create a visual market map of an industry.
            </li> */}
            <li className='mt-6'>
              Cartographer - A marketmap generation tool that takes a query industry and visualizes the key startups in that category, divided by subcategory.
            </li>

          </div>

          <p className="prose">
            Our core team is {" "}
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/katherinekeller1997/">Katie Keller</a>,{" "}
            <a target="_blank" rel="noreferrer" href="https://twitter.com/nicolas_ouporov">Nicolas Ouporov</a>, and{" "}
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/timothytully/">
              Tim Tully
            </a>
            . This project's inception came from a hackathon with help from Daniel Hunter, Emma Qian, and Haden Wasserbaech.
          </p>

          <p className="prose">
             
            This website is built with Pinecone, Next.js, React, and Flask. It is hosted with AWS.
          </p>

          <div className="mt-10 flex w-full justify-center">
            <Link to="/" className='button-primary'>
                <ArrowLeftIcon className="icon" />
                <p>Back</p>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}
